<script>

import router from "@/router";
import {defineComponent} from "vue";

export default defineComponent({
  methods: {
    router() {
      return router
    }
  }
})

</script>
<!-- NewYearHeader.vue -->
<template>
  <header class="
   relative
   bg-black
   bg-gradient-to-r
   w-full h-[500px]
   overflow-hidden
   from-indigo-600
   to-purple-600
">
    <!-- Background Image with Year New Celebration Theme -->
    <div
        class="absolute inset-0 bg-cover bg-center"
        style="background-image: url('static/img/newyear.webp'); filter: blur(10px);"

    ></div>

    <!-- Content Container -->
    <div class="relative z-10 flex flex-col md:flex-row items-center justify-between px-6 md:px-12 lg:px-24 h-full">
      <!-- Text Section -->
      <div class=" text-center md:text-left max-w-xl space-y-4">
        <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight">
         Happy New Year 2025
        </h1>

        <div class="flex justify-center md:justify-start space-x-4 pt-6">
          <button
              @click="router().push('/Home')"
              class="text-purple-600 hover:bg-gray-100 px-6 py-3 rounded-full font-semibold transition duration-300 ease-in-out transform hover:scale-105"
          >
          Home
          </button>
          <button @click="router().push('/store')"
          class="border-2 border-white hover:bg-white hover:text-purple-600 px-6 py-3 rounded-full font-semibold transition duration-300 ease-in-out transform hover:scale-105"
          >
            Ver Productos
          </button>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-full md:w-1/2 lg:w-[400px] mt-8 md:mt-0 animate-bounce-slow"
      style="margin-top: 100px">
        <img
            src="static/img/newyear.webp"
            alt="Cotillón Año Nuevo"
            class="w-full h-auto object-contain rounded-xl shadow-2xl"
            style="max-width: 50%; min-width: 400px; max-height: 400px;"
        />
      </div>
    </div>

    <!-- Decorative Elements -->
    <div class="absolute bottom-0 left-0 right-0 h-16 bg-white/10 backdrop-blur-sm"></div>
  </header>
</template>



<style scoped>
.animate-bounce-slow {
  animation: bounce-slow 3s infinite;
}

@keyframes bounce-slow {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}
</style>
