<script>
export default {
  name: 'HomeView',
  methods: {
    track() {
      this.$gtag.screenview({
        app_name: 'HappyApp',
        screen_name: 'Home',
      })
      this.$gtag.linker({
        'domains': ['happyhousefiesta.cl']
      })
    }
  }
}
</script>

<script setup>
  import { onBeforeMount } from 'vue';
  import { storeToRefs } from 'pinia';
  import MainLayout from '@/layouts/Layout.vue';
  import { useAppData, useCart } from '@/store';
  import { formatCurrency } from '@/composables/format';
  import birthdaySaleBanner from "@/views/birthday-sale-banner.vue";
  import Carousel from "@/layouts/Carousel.vue";

  const { activeProductList: products } = storeToRefs(useAppData());
  const { cart } = storeToRefs(useCart());
  const { getProductList } = useAppData();
  const { addProduct } = useCart();

  const floatformat = (value = 0) => Number(value).toFixed(0);
  const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

  function checkIfAdded(product) {
    const prod = cart.value.find(el => el.id === product.id);
    return !!(prod);
  }

  function setDefaultImage(productId) {
    let target = products.value.find(e => e.id === productId)
    const index = products.value.findIndex(e => e.id === productId)
    target.image = '/static/img/defecto.jpeg';
    products.value[index] = target;
    products.value = [...products.value];
  }

  onBeforeMount(() => {
    getProductList('', { category: 'Año Nuevo' });
  });
  let isVisible = false;

</script>

<template>
  <birthday-sale-banner @banner-click="handleClick" v-if="isVisible"></birthday-sale-banner>

  <main-layout>
    <div class="container">
      <carousel></carousel>
    </div>
    <!--
    <div id="carouselexampledark">
      <article class="back-image" style="background-image: url('/static/img/Home-Navidad2.png');">
      </article>
    </div>
    -->

    <div class="container marketing" id="marketing">
      <div class="row no-border content novedades mt-4">
        <h3 class="mb-4">
          Popular
        </h3>

        <div class="w-100 d-flex row just-center mx-auto gap-4">

            <div class="category-listing col-12 col-sm-3 mb-4 mb-sm-0 p-0">
              <a href="/store?category=Accesorios">
              <section class="col-12 p-0">
                <img class="img-fluid" src="/static/img/Home-Cotillon.jpeg" alt="Cotillon">
              </section>
              <h3 class="fw-normal">Cotillon</h3>
              </a>
            </div>

          <div class="category-listing col-12 col-sm-3 mb-4 mb-sm-0 p-0 ">
            <a href="/store?category=ACCESORIO%20FESTEJADO">
            <section class="col-12 p-0">
              <img class="img-fluid" src="/static/img/Home-Cumpleanios.jpeg" alt="Cumpleaños">
            </section>
            <h3 class="fw-normal">Cumpleaños</h3>
            </a>
          </div>

          <div class="category-listing col-12 col-sm-3 mb-4 mb-sm-0 p-0">
            <a href="/store?category=DISFRAZ">
            <section class="col-12 p-0">
              <img class="img-fluid" src="/static/img/Home-Disfraces.jpeg" alt="Disfraces">
            </section>
            <h3 class="fw-normal">Disfraces</h3>
            </a>
          </div>
        </div>
      </div>

      <div class="row no-border content novedades mt-4">
        <h3 class="mb-4">
          Novedades
        </h3>

        <div class="w-100 d-flex flex-row flex-nowrap mx-auto gap-4 product-grid pt-4 pb-3">
          <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 p-0" v-for="product in products.slice(0, 9)"
            v-bind:key="product.id">
            <div class="card box-shadow mx-auto h-100">
              <img class="img-fluid" v-bind:src="product.image" v-bind:alt="capfirst(product.name)"
                v-on:error="setDefaultImage(product.id)" />

              <div class="card-body">
                <div class="d-flex justify-content-between mb-2 mb-md-3">
                  <h5 class="mb-0 truncated text-start">{{ capfirst(product.name) }}</h5>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <h6 class="text-dark mb-0">{{ formatCurrency(product.price) }} </h6>
                </div>

                <div class="d-flex justify-content-between mb-2 align-items-center">
                  <h6 class="text-muted mb-0">
                    Disponible:
                    <span class="fw-bold">
                      {{ floatformat(product.quantity_available) }}
                    </span>
                  </h6>
                  <button v-bind:data-product="product.id" data-action="add" type="button" class="btn-sm"
                    v-bind:class="['btn', 'add-btn', 'update-cart', !checkIfAdded(product) ? 'btn-outline-danger' : 'btn-danger']"
                            v-on:click="handleBuy(product)">
                    <svg v-if="!checkIfAdded(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
                      <path
                        d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
                      <path
                        d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<style lang="scss">
  img.logo_image {
    position: absolute;
    width: 360px;
    display: inline-block;
    bottom: 25%;
    margin-bottom: 0px;
    filter: drop-shadow(0px 12px 48px white);
  }

  .rounded {
    border-radius: 50% !important;
  }

  #container2{
    position: absolute;
  }

  #carouselexampledark {
    margin: 0 auto;
    display: flex;
    position: relative;
    height: calc(50vh);
    min-height: calc(50vh);
    background: linear-gradient(90deg, #33b7e2, #5e62b0, #dc307c);
    background-size: cover;
    width: 300px;
    min-width: 300px;

    .back-image {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: flex-start;
      background-position: center;
      background-repeat: no-repeat;
      background-clip: border-box;
      background-size: cover;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .page-info {
      display: flex;
      width: 100%;
      height: 100%;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .next {
        display: flex;
        width: auto;
        height: 128px;

        .marker-to-next {
          color: white;
          animation: bounce 0.5s;
          animation-direction: alternate;
          animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
          animation-iteration-count: infinite;

          svg,
          img {
            width: 56px;
          }
        }
      }
    }
  }

  .content {
    background: white;
    padding: 36px;
    border-radius: 8px;
    justify-content: center;
  }

  .container.marketing {
    margin: 24px auto;

    .category-listing {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: cover;
        transition: all ease-in-out .2s;
      }

      h3 {
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        margin: 0;
        position: absolute;
        width: 100%;
        bottom: 0px;
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-size: 1rem;
        padding: 10px 10px 10px 10px;
        font-weight: 800;
        transition: all ease-in-out .16s;
        justify-content: center;
        align-items: center;
      }

      &:hover,
      &:focus,
      &:active {
        h3 {
          padding: 10px 10px 20px 10px;
        }

        img {
          // filter: brightness(0.5);
          opacity: 0.75;
        }
      }
    }
  }

  .no-border {
    border-radius: 0px;

    &:nth-of-type(2) {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-of-type {
      border-radius: 0px 0px 8px 8px;
    }
  }

  hr.featurette-divider {
    border-color: white;
    border-width: thick;
  }

  .title {
    font-family: 'Fredoka', fantasy;
    color: white;
    font-size: 96px;
    letter-spacing: 4px;
    font-weight: 600;
    transition: all ease-in-out .25s;
    animation: tilt-n-move-shaking 2s infinite;
  }

  .subtitle {
    color: white;
    font-family: 'Fredoka', fantasy;
    font-size: 28px;
    font-weight: 300;
    margin-top: 24px;
  }

  .just-center {
    justify-content: center;
  }


  .box-shadow {
    -webkit-box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
    box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  .box-shadow:hover {
    -webkit-box-shadow: 0 20px 40px rgba(72, 78, 85, .6);
    box-shadow: 0 20px 40px rgba(72, 78, 85, .6);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  .card {
    min-height: 250px;
    border-radius: 25px;
    max-height: 384px;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .card {
      max-height: 320px;
    }
  }

  .card img {
    max-height: calc(100% - 144px);
    object-fit: cover;
    margin: auto 0;
  }

  .card-body {
    max-height: 144px;
  }

  .product-grid {
    max-height: 384px;
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar {
      width: 12px;
      /* Ancho de la barra de desplazamiento */
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* Color de fondo de la pista */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      /* Color de la barra de desplazamiento */
      border-radius: 10px;
      border: 3px solid #f1f1f1;
      /* Espacio alrededor de la barra */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
      /* Color de la barra al pasar el ratón */
    }
  }

  @media screen and (max-width: 768px) {
    .card {
      min-height: 320px;
    }
  }

  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }

    25% {
      transform: translate(5px, 5px) rotate(5deg);
    }

    50% {
      transform: translate(0, 0) rotate(0deg);
    }

    75% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, 80px, 0);
    }
  }
</style>
